import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, first, switchMap, takeUntil } from 'rxjs/operators';
import { QBToken } from '../models';
import { QuickbooksService } from '../services';

@Injectable()
export class QuickbooksInterceptor implements HttpInterceptor {
	constructor(private quickbooksService: QuickbooksService) {}

	/**
	 * TODO The interceptor works in handing the auth popup. But after auth does not handle retry
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse && error.status === 401 && error.error?.authUrl) {
					console.warn('🔐 Opening QB reauth popup...');

					// open popup + wait for new valid token to appear
					return this.waitForReauthToken(error.error.authUrl).pipe(
						switchMap(() => {
							console.log('✅ Reauth complete, retrying request...');
							return next.handle(req); // retry original
						}),
						catchError(err => throwError(() => err))
					);
				}

				return throwError(() => error);
			})
		);
	}

	private waitForReauthToken(authUrl: string): Observable<QBToken> {
		const popup = window.open(authUrl, '_blank', 'width=600,height=700');
		if (!popup) {
			console.warn('❌ Popup blocked');
			return throwError(() => new Error('Popup blocked'));
		}

		const timeout$ = timer(15_000); // ⏱️ 15 sec timeout

		// 🧠 Wait for a token with status: 'idle' and a valid access token
		const token$ = this.quickbooksService.getLastTokenAsObservable().pipe(
			switchMap(tokens => {
				const valid = tokens.find(t => t?.status === 'idle' && !!t?.access_token);
				return valid ? [valid] : [];
			}),
			first(), // take first valid token
			takeUntil(timeout$)
		);

		return token$;
	}
}
