import { collection, doc, setDoc } from '@angular/fire/firestore';
import { Notifications, SkillGroup } from '.';
import { MAS_Accounts_Service } from '../services';
import { AdvancedDebugging, AnyObject, DateTimeUtils } from '../utilities';

export interface Account extends Addresses, Birthdays, Emails, MAS, Memberships, Names, PhoneNumbers, URLS {
	id: string;
}

export interface AccountAttendance {
	cycleName: string;
	scheduleId: string;
	scheduleName: string;
	startTime: string;
}

export type AccountSettings = {
	activeDate?: string;
	alternateTestCategory?: string;
	billing: 'true' | 'false';
	enableEmailReminders?: boolean;
	enableSMS?: boolean;
	gender: string;
	inactiveDate?: string;
	legacy?: 'student' | 'leadership' | 'level-0' | 'level-1' | 'level-2' | 'level-3' | 'staff' | 'none';
	member: 'true' | 'false';
	profilePicture?: string;
	school?: string;
	status: string;
	timeCreated?: string;
	trialConversion?: boolean;
	trialEndDate?: string;
};

export interface AccountEditMode {
	accountType?: 'active' | 'addParent' | 'child' | 'existing' | 'inactive' | 'legacy' | 'new' | 'schedules' | 'violations' | 'yas';
	editState: boolean;
	account?: Account;
}

export interface AccountFilter {
	add: string;
	default: string;
	inactive: string;
	new: string;
	pending: string;
	schedules: string;
	violations: string;
	yas: string;
}

export interface Addresses {
	addresses?: {
		city: string;
		postalCode: string;
		region: string;
		streetAddress: string;
		type?: string;
	};
}

export interface AttendanceViolation {
	action?: 'waive' | 'fee';
	cycleName: string;
	endDate: string;
	id?: string;
	notifications: Notifications;
	scheduleId: string;
	summary: string;
}

export interface Birthdays {
	birthdays?: { text: string };
}

export interface Emails {
	emailAddresses?: {
		type: string;
		value: string;
	};
}

export interface gapi extends gapiToken, gapiUser {
	masAccountId?: string;
	userCredential?: any;
}

export interface gapiToken {
	token: {
		access_token: string;
		expiry_date: number;
		id_token: string;
		refresh_token: string;
		scope: string;
		token_type: string;
	};
}

export interface gapiUser {
	user: {
		email: string;
		id: string;
	};
}

export interface LinkedAccounts {
	accountId: string;
	person: string;
	type: 'parent' | 'child';
}

export interface MAS {
	mas: {
		accountSettings: AccountSettings;
		acknowledgements?: string[];
		ataNumber?: string;
		gapi?: gapi;
		linkedAccounts?: LinkedAccounts[];
		memberAttendanceCumulative?: { [key: string]: number };
		nodeType: 'parent' | 'child';
		quickbooks?: QuickbooksCustomer;
		skills?: SkillGroup[];
		tournaments?: 'blue' | 'red' | 'silver' | 'gold' | 'none' | 'jahngsoo';
		triviaQuestionData?: TriviaQuestionData;
	};
}

export interface Memberships {
	memberships?: string[];
}

export interface Names {
	names: {
		familyName: string;
		givenName: string;
	};
}

/**
 * @deprecated Use `NewAccountInterface` instead.
 */
export interface Node {
	billing: string;
	children?: unknown[];
	collapsedIcon?: 'pi pi-user';
	data: Account;
	expanded?: null;
	expandedIcon?: 'pi pi-users';
	key: string;
	label: string;
	status: string;
}

export interface PhoneNumbers {
	phoneNumbers?: {
		type: string;
		value: string;
	};
}

export interface QuickbooksCustomer {
	Active?: boolean;
	BillAddr?: {
		City?: string;
		Country?: string;
		CountrySubDivisionCode?: string;
		Line1?: string;
		PostalCode?: string;
	};
	DisplayName?: string;
	FamilyName?: string;
	FullyQualifiedName?: string;
	GivenName?: string;
	Id?: string;
	MetaData?: {
		CreateTime?: string;
		LastUpdatedTime?: string;
	};
	PrimaryEmailAddr: { Address: string };
	PrimaryPhone?: { FreeFormNumber: string };
	SyncToken: string;
	sparse: boolean;
}

export interface TournamentParticipation {
	tournamentDate: string;
	tournamentName: string;
}

export interface TriviaQuestionData {
	cumulativeCorrect: number;
	lastCorrectDate: string;
	lastDate: string;
}

export interface URLS {
	urls?: any;
}

export class MASAccountModel {
	[x: string]: any;
	#data: Account;

	private advancedDebug = new AdvancedDebugging().closeNDeep(10).setLogStyle('color: lightpurple;');

	constructor(data: Account, private accountService: MAS_Accounts_Service) {
		this.#data = data;
	}

	// Access to raw Firestore data
	get accountSettings() {
		return this.raw.mas.accountSettings;
	}

	get age(): number | null {
		const birthday = this.raw.birthdays?.text;
		if (!birthday) return null;
		return DateTimeUtils.calculateAge(birthday);
	}

	get alternateTestCategory(): string {
		return this.raw.mas.accountSettings.alternateTestCategory?.trim() || 'None';
	}

	set alternateTestCategory(value: string) {
		this.raw.mas.accountSettings.alternateTestCategory = value;
	}

	get legacy(): string {
		return this.raw.mas.accountSettings.legacy?.trim() || 'none';
	}

	set legacy(value: 'student' | 'leadership' | 'level-0' | 'level-1' | 'level-2' | 'level-3' | 'staff' | 'none') {
		this.raw.mas.accountSettings.legacy = value;
	}

	get birthdayAsDate(): Date | null {
		const val = this.raw.birthdays?.text;
		return val ? DateTimeUtils.parseDate(val) : null;
	}

	get birthdayDisplay(): string {
		const val = this.raw.birthdays?.text;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	get fullName(): string {
		const { givenName = '', familyName = '' } = this.#data.names || {};
		return `${givenName} ${familyName}`.trim();
	}

	get hasSchool(): boolean {
		const isChild = this.raw?.mas?.nodeType === 'child';
		const birthdayStr = this.raw?.birthdays?.text;
		const age = birthdayStr ? DateTimeUtils.calculateAge(birthdayStr) : null;

		return isChild && age !== null && age < 18;
	}

	get id(): string {
		return this.#data.id;
	}

	get isBilling(): boolean {
		return this.#data?.mas?.accountSettings?.billing === 'true';
	}

	get isChild(): boolean {
		return this.#data.mas.nodeType === 'child';
	}

	get isInactive(): boolean {
		return this.status === 'Inactive';
	}

	get isMember(): boolean {
		return this.#data.mas.accountSettings.member === 'true';
	}

	get isParent(): boolean {
		return this.#data.mas.nodeType === 'parent';
	}

	get isTrial(): boolean {
		return this.#data.mas.accountSettings.status === 'Trial';
	}

	get isInstructor(): boolean {
		return this.#data.mas.accountSettings.legacy === 'level-3';
	}

	get isStaff(): boolean {
		return this.#data.mas.accountSettings.legacy === 'staff';
	}

	get raw(): Account {
		return this.#data;
	}

	get status(): string {
		return this.#data.mas.accountSettings.status;
	}

	get trialEndDateAsDate(): Date | null {
		const val = this.raw.mas.accountSettings.trialEndDate;
		return val ? DateTimeUtils.parseDate(val) : null;
	}

	get trialEndDateDisplay(): string {
		const val = this.raw.mas.accountSettings.trialEndDate;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	get inactiveDateDisplay(): string {
		const val = this.raw.mas.accountSettings.inactiveDate;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	get activeDateDisplay(): string {
		const val = this.raw.mas.accountSettings.trialEndDate;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	set trialConversion(value: boolean) {
		this.raw.mas.accountSettings.trialConversion = value;
	}
	applyEditModeType(type: 'child' | 'addParent' | 'existing' | string | undefined) {
		if (type === 'child') this.raw.mas.nodeType = 'child';
		else if (type === 'addParent') this.raw.mas.nodeType = 'parent';
	}

	autoFix(): string[] {
		this.advancedDebug.setLogStyle('color: pink; background: purple');
		const violations: string[] = [];
		const { mas } = this.raw;
		mas.linkedAccounts ??= [];

		// Validate settings object first
		mas.accountSettings ??= {
			billing: 'false',
			gender: 'unspecified',
			member: 'false',
			status: 'Inactive',
			legacy: 'none',
		};

		const { nodeType, accountSettings } = mas;
		const linkedAccounts = mas.linkedAccounts;
		const isChild = nodeType === 'child';
		const isParent = nodeType === 'parent';
		const isMember = accountSettings.member === 'true';
		const age = this.age;

		// Normalize and deduplicate linkedAccounts first
		mas.linkedAccounts = linkedAccounts.filter(acc => acc?.accountId).filter((acc, i, arr) => arr.findIndex(a => a.accountId === acc.accountId) === i);

		// Clean up obsolete `data` field
		mas.linkedAccounts = mas.linkedAccounts.map(acc => {
			const { data, ...cleaned } = acc as any;
			return cleaned;
		});

		if (isChild) {
			// Valid parent entries only
			mas.linkedAccounts = mas.linkedAccounts.filter(acc => acc.type === 'parent');

			if (mas.linkedAccounts.length !== 1 && accountSettings.status === 'Active') {
				violations.push('An active child must have exactly one valid parent-linked account.');
			}

			if (mas.linkedAccounts.length === 1 && mas.linkedAccounts[0].type !== 'parent') {
				mas.linkedAccounts[0].type = 'parent';
			}

			if (accountSettings.billing !== 'false') accountSettings.billing = 'false';

			if (!isMember) accountSettings.member = 'true';

			if ((age !== null && age < 18 && !accountSettings.school) || !('school' in accountSettings)) {
				this.advancedDebug.log(`Fixing missing school for child ${this.id}`);
				accountSettings.school = 'Other';
			}

			if (this.raw.emailAddresses) {
				delete this.raw.emailAddresses;
			}
		}

		if (isParent) {
			// All child types, deduplicated
			mas.linkedAccounts = mas.linkedAccounts.map(acc => ({ ...acc, type: 'child' as const })).filter((acc, i, arr) => arr.findIndex(a => a.accountId === acc.accountId) === i);

			if (!isMember) {
				if (accountSettings.status === 'Trial') {
					accountSettings.status = 'Active';
				}
				if ('birthdays' in this.raw) {
					this.advancedDebug.log(`Removing birthday from non-member parent ${this.id}`);
					delete this.raw.birthdays;
				}
				if ('trialEndDate' in accountSettings) {
					this.advancedDebug.log(`Removing Trial End Date from non-member parent ${this.id}`);
					delete accountSettings.trialEndDate;
				}
				if (!Array.isArray(this.raw.memberships)) {
					this.advancedDebug.log(`Fixing memberships property on non-member ${this.id}`);
					this.raw.memberships = [];
				}
			}
		}

		// MEMBERSHIP FIXES
		if (isMember) {
			const t = mas.tournaments;
			const isInvalid = !t || typeof t !== 'string' || t.trim().length === 0;
			if (isInvalid) {
				this.advancedDebug.log(`Fixing tournaments on ${this.id}`, { originalValue: t });
				mas.tournaments = 'none';
			}
			if (!('alternateTestCategory' in accountSettings)) {
				this.advancedDebug.log(`Fixing missing alternateTestCategory on member ${this.id}`);
				accountSettings.alternateTestCategory = 'None';
			}
			if (!('legacy' in accountSettings)) {
				this.advancedDebug.log(`Fixing missing legacy status on member ${this.id}`);
				accountSettings.legacy = 'student';
			}
			if (accountSettings.status === 'Trial' && (accountSettings.trialConversion === true || accountSettings.trialConversion === undefined)) {
				accountSettings.trialConversion = false;
			}
			if (accountSettings.status === 'Active' && (accountSettings.trialConversion === false || accountSettings.trialConversion === undefined)) {
				accountSettings.trialConversion = true;
			}
			if (accountSettings.status === 'Active' && 'timeCreated' in accountSettings && !('activeDate' in accountSettings)) {
				this.advancedDebug.log(`Setting activeDate from timeCreated on member ${this.id}`, {
					timeCreated: accountSettings.timeCreated,
				});
				accountSettings.activeDate = accountSettings.timeCreated;
			}
		} else {
			// ONLY run this if NOT a member
			if ('alternateTestCategory' in accountSettings) {
				this.advancedDebug.log(`Removing alternateTestCategory from non-member ${this.id}`);
				delete accountSettings.alternateTestCategory;
			}
			if ('tournaments' in mas) {
				this.advancedDebug.log(`Removing tournament data from non-member ${this.id}`);
				delete mas.tournaments;
			}
			if (!accountSettings.legacy) {
				this.advancedDebug.log(`Fixing missing legacy status ${this.id}`);
				accountSettings.legacy = 'none';
			}
		}

		const result = this.validate();
		return result.violations;
	}

	clearLinks(): this {
		this.raw.mas.linkedAccounts = [];
		return this;
	}

	clearMemberships(): this {
		this.raw.memberships = [];
		return this;
	}

	clone(): MASAccountModel {
		const clonedRaw: Account = JSON.parse(JSON.stringify(this.raw));
		return new MASAccountModel(clonedRaw, this.accountService);
	}

	correctLinkName(account: MASAccountModel): this {
		const links = this.raw.mas?.linkedAccounts;
		if (Array.isArray(links)) {
			links.forEach(link => {
				if (link.accountId === account.raw.id) {
					link.person = account.fullName;
				}
			});
		}
		return this;
	}

	diffBetweenSnapshots(a: Account, b: Account): Record<string, { from: any; to: any }> {
		const diffs: Record<string, { from: any; to: any }> = {};
		this._findDifferencesSummary(a, b, [], diffs);
		return diffs;
	}

	diffRaw(otherRaw: Account): AnyObject {
		return this._findDifferences(this.raw, otherRaw) || {};
	}

	diffSummaryRaw(aOrOtherRaw: Account | any, bRaw?: any): Record<string, { from: any; to: any }> {
		const a = bRaw ? aOrOtherRaw : this.raw;
		const b = bRaw ? bRaw : aOrOtherRaw;

		const changed: Record<string, { from: any; to: any }> = {};
		this._findDifferencesSummary(a, b, [], changed);
		return changed;
	}

	async getLinkedAccounts(): Promise<MASAccountModel[]> {
		const ids = this.#data.mas.linkedAccounts?.map(x => x.accountId).filter(Boolean);
		if (!ids?.length) return [];
		const accounts = await this.accountService.getAccountsByLinked(ids);
		return accounts.map(acc => new MASAccountModel(acc, this.accountService));
	}

	hasChildren(): boolean {
		return !!this.#data.mas.linkedAccounts?.length;
	}

	private isDate(value: unknown): value is Date {
		return value instanceof Date && !isNaN(value.getTime());
	}

	linkAccount(account: Account): void {
		this.advancedDebug.setLogStyle('color: pink; background: purple');
		try {
			this.advancedDebug.log('MASAccountModel=>linkAccount=>account', account);

			const newLink: LinkedAccounts = {
				accountId: account.id,
				person: `${account.names.givenName} ${account.names.familyName}`,
				type: account.mas.nodeType as 'parent' | 'child',
			};
			this.advancedDebug.log('MASAccountModel=>linkAccount=>newLink', newLink);

			if (!this.raw.mas.linkedAccounts) {
				this.raw.mas.linkedAccounts = [];
			}

			// Avoid duplicate linking
			const alreadyLinked = this.raw.mas.linkedAccounts.some(link => link.accountId === newLink.accountId);

			if (!alreadyLinked) {
				this.raw.mas.linkedAccounts.push(newLink);
			}
			this.advancedDebug.log('MASAccountModel=>mas.linkAccount', this.raw.mas.linkedAccounts);
		} catch (error) {
			this.advancedDebug.captureError('MASAccountModel=>linkAccount', error, { account });
		}
	}

	removeATANumber(): this {
		return this._removeField('mas.ataNumber');
	}

	removeBirthday(): this {
		return this._removeField('birthdays');
	}

	removeEmail(): this {
		return this._removeField('emailAddresses');
	}

	removeLinkedAccounts(): this {
		return this._removeField('mas.linkedAccounts');
	}

	removeLinkToChild(childId: string): this {
		this.raw.mas.linkedAccounts = this.raw.mas.linkedAccounts?.filter(link => link.accountId !== childId) ?? [];
		return this;
	}

	removeNotifications(): this {
		this._removeField('mas.accountSettings.enableEmailReminders');
		this._removeField('mas.accountSettings.enableSMS');
		return this;
	}

	removeQuickbooks(): this {
		return this._removeField('mas.quickbooks');
	}

	removeSchool(): this {
		return this._removeField('mas.accountSettings.school');
	}

	removeTrialEndDate(): this {
		return this._removeField('mas.accountSettings.trialEndDate');
	}

	removeInactiveDate(): this {
		return this._removeField('mas.accountSettings.inactiveDate');
	}

	removeTournaments(): this {
		return this._removeField('mas.tournaments');
	}

	removeAlternateTesting(): this {
		return this._removeField('mas.accountSettings.alternateTestCategory');
	}

	save(state: 'existing' | 'new', merge: boolean = true, options?: { history: Record<string, any>; userId: string; caller: string }): Promise<Account> {
		this.advancedDebug.setLogStyle('color: pink; background: purple');
		const collectionPath = 'mas-accounts';
		const firestore = this.accountService.firestore;

		return new Promise(async (resolve, reject) => {
			try {
				if (state === 'existing' && this.raw.id) {
					const ref = doc(firestore, collectionPath, this.raw.id);
					await setDoc(ref, this.raw, { merge });
					this.advancedDebug.log('MASAccountModel.save()=>existing', this.raw);
					resolve(this.raw);
				} else {
					const collectionRef = collection(firestore, collectionPath);
					const newDocRef = doc(collectionRef);
					this.raw.id = newDocRef.id;
					await setDoc(newDocRef, this.raw);
					this.advancedDebug.log('MASAccountModel.save()=>New', this.raw);
					resolve(this.raw);
				}
			} catch (error) {
				this.advancedDebug.captureError('MASAccountModel.save()', error);
				reject(error);
			} finally {
				this.advancedDebug.log('MASAccountModel.save()=>finally', options);
				if (options) {
					await this.logHistory(options.history, options.userId, options.caller);
				}
			}
		});
	}

	setInactiveDate(date?: Date): this {
		this.raw.mas.accountSettings.inactiveDate = DateTimeUtils.getNewYorkDateString(date);
		return this;
	}

	setActiveDate(date?: Date): this {
		this.raw.mas.accountSettings.activeDate = DateTimeUtils.getNewYorkDateString(date);
		return this;
	}

	setTimeCreated(date?: Date): this {
		this.raw.mas.accountSettings.timeCreated = DateTimeUtils.getNewYorkDateString(date);
		return this;
	}

	static createEmptyParent(accountService: MAS_Accounts_Service): MASAccountModel {
		const emptyAccount: Account = {
			id: '',
			names: { givenName: '', familyName: '' },
			emailAddresses: { type: 'other', value: '' },
			phoneNumbers: { type: 'other', value: '' },
			addresses: {
				streetAddress: '',
				city: '',
				region: '',
				postalCode: '',
			},
			mas: {
				accountSettings: {
					billing: 'true',
					member: 'false',
					gender: '',
					status: 'Active',
					school: '',
					trialEndDate: '',
					alternateTestCategory: '',
				},
				tournaments: 'none',
				ataNumber: '',
				nodeType: 'parent',
				linkedAccounts: [],
			},
			memberships: [],
		};
		return new MASAccountModel(emptyAccount, accountService);
	}

	toJSON(): Account {
		return this.#data;
	}

	updateFromForm(formValue: Partial<Account>) {
		this.raw.names = formValue.names ?? this.raw.names;
		this.raw.phoneNumbers = formValue.phoneNumbers ?? this.raw.phoneNumbers;
		this.raw.addresses = formValue.addresses ?? this.raw.addresses;

		if (formValue.emailAddresses?.value) {
			this.raw.emailAddresses = {
				...formValue.emailAddresses,
				value: formValue.emailAddresses.value.toLowerCase(),
			};
		} else {
			this.raw.emailAddresses = formValue.emailAddresses ?? this.raw.emailAddresses;
		}

		this.raw.mas.accountSettings = {
			...this.raw.mas.accountSettings,
			...formValue.mas?.accountSettings,
		};

		this.raw.mas.accountSettings = {
			...this.raw.mas.accountSettings,
			...formValue.mas?.accountSettings,
		};

		// ✅ Fix: Prevent blank tournament values from being saved
		const tournaments = formValue.mas?.tournaments;
		if (tournaments && tournaments.trim() !== '') {
			this.raw.mas.tournaments = tournaments;
		} else {
			delete this.raw.mas.tournaments;
		}

		this.raw.mas.ataNumber = formValue.mas?.ataNumber ?? this.raw.mas.ataNumber;

		this.raw.mas.ataNumber = formValue.mas?.ataNumber ?? this.raw.mas.ataNumber;

		if (this.raw.mas.accountSettings.member === 'true') {
			const bday = formValue.birthdays?.text;
			if (this.isDate(bday)) {
				this.raw.birthdays = { text: DateTimeUtils.formatDate(bday, 'date') };
			} else if (bday) {
				this.raw.birthdays = { text: bday };
			} else {
				delete this.raw.birthdays;
			}
		} else {
			delete this.raw.birthdays;
		}

		const trialDate = formValue.mas?.accountSettings?.trialEndDate;
		if (this.isDate(trialDate)) {
			this.raw.mas.accountSettings.trialEndDate = DateTimeUtils.formatDate(trialDate, 'date');
		} else if (typeof trialDate === 'string') {
			this.raw.mas.accountSettings.trialEndDate = trialDate;
		} else {
			delete this.raw.mas.accountSettings.trialEndDate;
		}

		return this;
	}

	validate(): { valid: boolean; violations: string[] } {
		const violations: string[] = [];
		const { mas, emailAddresses, birthdays, memberships } = this.raw;
		const { nodeType, linkedAccounts = [], accountSettings } = mas;
		const isChild = nodeType === 'child';
		const isParent = nodeType === 'parent';
		const isMember = accountSettings.member === 'true';
		const age = this.age;

		if (isChild) {
			if (linkedAccounts.length !== 1) {
				violations.push('Child must have exactly one linked account.');
			}

			const hasWrongType = linkedAccounts.some(acc => acc.type !== 'parent');
			if (hasWrongType) {
				violations.push('Child must only be linked to parent accounts.');
			}

			const duplicateIds = linkedAccounts.map(a => a.accountId).filter((id, i, arr) => id && arr.indexOf(id) !== i);
			if (duplicateIds.length > 0) {
				violations.push('Child linkedAccounts must not contain duplicates.');
			}

			if (linkedAccounts.some(acc => 'data' in acc)) {
				violations.push('Child linkedAccounts must not contain the "data" property.');
			}

			if (accountSettings.billing !== 'false') {
				violations.push('Child cannot have billing set to true.');
			}

			if (!isMember) {
				violations.push('Child must have member set to true.');
			}

			if (age !== null && age < 18 && !accountSettings.school) {
				violations.push(`${this.fullName} ${this.raw.id} - Child under 18 must have a school specified.`);
			}

			if (emailAddresses) {
				violations.push('Child account cannot have email addresses.');
			}
		}

		if (isParent) {
			const safeLinkedAccounts = linkedAccounts ?? [];

			if (safeLinkedAccounts.some(acc => acc.type !== 'child')) {
				violations.push('Parent must only be linked to child accounts.');
			}

			const duplicateIds = safeLinkedAccounts.map(a => a.accountId).filter((id, i, arr) => id && arr.indexOf(id) !== i);

			if (duplicateIds.length > 0) {
				violations.push('Parent linkedAccounts must not contain duplicates.');
			}

			if (safeLinkedAccounts.some(acc => 'data' in acc)) {
				violations.push('Parent linkedAccounts must not contain the "data" property.');
			}

			if (!isMember) {
				if (birthdays) {
					violations.push('Non-member parent should not have a birthday.');
				}
				if (accountSettings.status === 'Trial') {
					violations.push('Non-member parent cannot have status "Trial".');
				}
				if ('trialEndDate' in accountSettings && accountSettings.trialEndDate) {
					violations.push('Non-member parent should not have a trialEndDate.');
				}
				if ('tournaments' in mas) {
					violations.push('Non-member parent should not have tournament data.');
				}
				if ((memberships ?? []).length > 0) {
					violations.push('Non-member parent should not have memberships.');
				}
			}

			if ('school' in accountSettings && accountSettings.school) {
				violations.push('Parent account should not have a school assigned.');
			}

			if (!emailAddresses) {
				violations.push('Parent account should have an email address.');
			}
		}

		if (isMember) {
			if (!birthdays?.text) {
				violations.push('Member account should have a birthday.');
			}

			const VALID_TOURNAMENTS = ['blue', 'red', 'silver', 'gold', 'none', 'jahngsoo'];
			const tournament = mas.tournaments;
			if (!VALID_TOURNAMENTS.includes(tournament as string)) {
				violations.push('Member account must have a valid tournament selection.');
			}
			if (!accountSettings.alternateTestCategory) {
				violations.push('Member account should have an alternate test category.');
			}
			if (accountSettings.status === 'Trial' && (accountSettings.trialConversion === true || accountSettings.trialConversion === undefined)) {
				violations.push('Member accounts with a status of Trial cannot have a trialConversion = true');
			}
			if (accountSettings.status === 'Active' && (accountSettings.trialConversion === false || accountSettings.trialConversion === undefined)) {
				violations.push('Member accounts with a status of Active cannot have a trialConversion = false');
			}
			if (accountSettings.status === 'Active' && 'timeCreated' in accountSettings && !('activeDate' in accountSettings)) {
				violations.push('An active member should have an active date');
			}
		}

		if (accountSettings.status === 'Inactive') {
			if (memberships && memberships.length > 0) {
				violations.push('Inactive account should not have a membership.');
			}
			if (linkedAccounts.length > 0) {
				violations.push('Inactive account should not have linked accounts.');
			}
			if (!accountSettings.inactiveDate) {
				violations.push('Inactive account should have an inactive date.');
			}
		}

		const VALID_LEGACY = ['student', 'leadership', 'level-0', 'level-1', 'level-3', 'level-3', 'staff', 'none'];
		const legacy = mas.accountSettings.legacy;
		if (!VALID_LEGACY.includes(legacy as string)) {
			violations.push('account must have a valid legacy selection.');
		}

		return {
			valid: violations.length === 0,
			violations,
		};
	}

	private _findDifferences(a: any, b: any): any {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			return a !== b ? a : undefined;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			return JSON.stringify(a) !== JSON.stringify(b) ? a : undefined;
		}

		const result: AnyObject = {};
		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);

		for (const key of keys) {
			const diff = this._findDifferences(a[key], b[key]);
			if (diff !== undefined) {
				result[key] = diff;
			}
		}

		return Object.keys(result).length > 0 ? result : undefined;
	}

	private _findDifferencesSummary(a: any, b: any, path: string[] = [], changed: Record<string, { from: any; to: any }>): void {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			if (a !== b) changed[path.join('.')] = { from: a, to: b };
			return;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			if (JSON.stringify(a) !== JSON.stringify(b)) {
				changed[path.join('.')] = { from: a, to: b };
			}
			return;
		}

		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);
		for (const key of keys) {
			this._findDifferencesSummary(a?.[key], b?.[key], [...path, key], changed);
		}
	}

	private _removeField(path: string): this {
		this.advancedDebug.setLogStyle('color: pink; background: purple');
		const segments = path.split('.');
		let obj: any = this.raw; // 👈 This allows dynamic indexing

		for (let i = 0; i < segments.length - 1; i++) {
			obj = obj?.[segments[i]];
			if (!obj) return this;
		}

		const lastKey = segments[segments.length - 1];
		if (lastKey in obj) {
			this.advancedDebug.log(`MASAccountModel_removeField=> ${path}`);
			delete obj[lastKey];
		}

		return this;
	}

	async logHistory(diff: Record<string, any>, userId: string, caller: string): Promise<void> {
		this.advancedDebug.setLogStyle('color: pink; background: purple');
		const firestore = this.accountService.firestore;
		const historyPath = `mas-accounts/${this.raw.id}/mas-accounts-history`;
		this.advancedDebug.log('MASAccountModel=>logHistory=>historyPath', historyPath);
		const historyRef = doc(collection(firestore, historyPath));

		const historyEntry = {
			timestamp: new Date(),
			changedBy: userId,
			caller: caller,
			changes: diff,
		};

		await setDoc(historyRef, historyEntry);
	}
}
