import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/modules/app.module';
import { firebase } from './environments/environment';

if (firebase.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));

const isLocalhost = window.location.hostname === 'localhost';

Sentry.init({
	dsn: 'https://98acde502446c7503edb5ba8b45f83ee@o4508904065204224.ingest.us.sentry.io/4508905054011392',
	enabled: !isLocalhost,
	environment: isLocalhost ? 'development' : 'production',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	release: 'Major Release 2025-04-05',
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	tracePropagationTargets: [/^https:\/\/us-central1-valiant-splicer-224515.cloudfunctions\.net/],
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

Sentry.startSession();

const originalConsoleError = console.error;
console.error = (...args: any[]) => {
	Sentry.captureMessage(args.join(' '), 'error'); // Send to Sentry
	originalConsoleError(...args); // Still log in console
};
